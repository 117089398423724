<template>
  <div>
    <!--<div id="header">Header</div>-->
    <div class="pop_dim" id="proposal_info" style="display: none;">
      <div class="pop_area">
        <div class="f_r" style="cursor: pointer;" @click="fn_setPopupCss('info')"><img src="../../assets/img/close_btn.png"></div>
        <div class="pop_tit">소개서 다운로드</div>
        <div class="pop_tit_sub">
          아래 정보를 입력하신 후 소개서 다운로드가 가능합니다.
        </div>
        <div style="padding: 0 20px;">
          <div>
            <div class="fl_input">
              <label>회사명 <span class="fc_o">*</span></label>
              <input
                  ref="companyNmTxRef"
                  maxlength="14"
                  v-model.trim = "vModel.companyNmTx"
                  name="companyNmTx"
                  id="companyNmTx"
                  @keydown.tab.prevent="fn_setTabIndexMove('phoneNoTx')"
                  style="'width: 100%;'"
              >
            </div>
            <div class="fl_input">
              <label>연락처<span class="fc_o">*</span></label>
              <input
                  ref="phoneNoTxRef"
                  maxlength="30"
                  v-model.trim = "vModel.phoneNoTx"
                  name="phoneNoTx"
                  id="phoneNoTx"
                  @keydown.tab.prevent="fn_setTabIndexMove('emailTx')"
                  placeholder="01012341234"
                  style="'width: 100%;'"
              >
            </div>
            <div class="fl_input">
              <label>이메일 <span class="fc_o">*</span></label>
              <input
                  ref="emailTxRef"
                  maxlength="33"
                  v-model.trim = "vModel.emailTx"
                  name="emailTx"
                  @keydown.tab.prevent="fn_setTabIndexMove('managerNmTx')"
                  id="emailTx"
                  :placeholder="'ex@etners.com'"
                  :style="'width: 100%;'"
              >
            </div>
            <div class="fl_input">
              <label>성명 <span class="fc_o">*</span></label>
              <input
                  ref="managerNmTxRef"
                  maxlength="14"
                  v-model.trim = "vModel.managerNmTx"
                  name="managerNmTx"
                  id="managerNmTx"
                  @keydown.tab.prevent="fn_setTabIndexMove('companyNmTx')"
                  :style="'width: 100%;'"
              >
            </div>
            <div>
              <div>
                <label>개인정보처리방침 동의 <span class="fc_o rg">*</span></label>
                <label class="checkbox">
                  <input type="checkbox" id="agree_pledge" v-model="vModel.agree_pledge">
                  <div>[필수] 개인정보처리방침에 동의합니다. <a href="https://etners.com/terms/management/detail/10/02" target="_blank" class="">보기</a></div>
                </label>
                <label class="checkbox">
                  <input type="checkbox" id="agree_marketing" v-model="vModel.agree_marketing" >
                  <div>[선택] 마케팅 활용에 동의합니다. <a href="https://etners.com/marketing.html?solCd=10" target="_blank" class="">보기</a></div>
                </label>
              </div>
            </div>
            <div class="pop_btn">
              <button @click="fnSubmit" style="cursor: pointer;" >등록하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop_dim" id="proposal_download" style="display: none;">
      <div class="pop_area" style="height: 430px;">
        <div class="f_r" style="cursor: pointer;" @click="fn_setPopupCss('down')"><img src="../../assets/img/close_btn.png"></div>
        <div style="text-align: center;margin-bottom: 20px;margin-top: 50px;"><img src="../../assets/img/pop_img.png"></div>
        <div class="pop_tit" style="color: #333;">
          소중한 정보 감사합니다!<br>
          <span >아래 버튼을 클릭하여 소개서를 다운받으실 수 있습니다.</span>
        </div>
        <div class="proposal_down"><a :href="downloadUrl"> 소개서 다운받기<img src="../../assets/img/down_ic.png" style="margin-left: 10px;"></a></div>
      </div>
    </div>
    <div id="fullpage">
      <div class="section" id="section1">
        <div class="btn_main01">
          <ul>
            <li :class="aboutSlidePage===0? 'on' : ''" @click="goSlide(1,0)"><a style="cursor: pointer;">01</a></li>
            <li :class="aboutSlidePage===1? 'on' : ''" @click="goSlide(1,1)"><a style="cursor: pointer;">02</a></li>
            <li :class="aboutSlidePage===2? 'on' : ''" @click="goSlide(1,2)"><a style="cursor: pointer;">03</a></li>
          </ul>
        </div>
        <div class="slide main0101" id="slide1">
          <div class="intro">
            <h1 class="main_copy">ETNERS<br>
              FULFILLMENT SOLUTION </h1>
            <div class="sub_title">EFS는 양방향 물류에 특화된 시스템으로
              컨설팅, 시스템 기획, 운영, 공급 등
              물류에 필요한 모든 서비스를 지원합니다.
            </div>
          </div>
        </div>
        <div class="slide main0102" id="slide2">
          <div class="intro">
            <h1 class="main_copy">ETNERS<br>
              FULFILLMENT SOLUTION</h1>
            <div class="sub_title">EFS만의 맞춤 컨설팅으로 통합 물류 운영 솔루션을 제공합니다.
            </div>
          </div>
        </div>
        <div class="slide main0103" id="slide3">
          <div class="intro">
            <h1 class="main_copy">ETNERS<br>
              FULFILLMENT SOLUTION</h1>
            <div class="sub_title">ETNERS FULFILLMENT SOLUTION을 통해 사업의 성공과 기업가치를 높여드립니다.</div>
          </div>
        </div>

      </div>
      <div class="section" id="section2">
        <div class="intro">
          <h1>통합 물류 운영 솔루션</h1>
          <div class="item solution">
            <div id="slider" class="flexslider box_shadow">
              <ul class="slides img_slide">
                <li>
                  <div class="text_area">
                    <div class="m2_title">실시간 모니터링</div>
                    <div class="m2_text">현장에서 진행되는 작업에 대하여 모니터링을 실시하여 신뢰도 향상
                    </div>
                  </div>
                  <img src="../../assets/img/bg_main0201.png"/></li>
                <li>
                  <div class="text_area">
                    <div class="m2_title">일별작업 물량관리</div>
                    <div class="m2_text">실시간 작업 현황 확인 및 일일 작업결과 자동발송
                    </div>
                  </div>
                  <img src="../../assets/img/bg_main0202.png"/></li>
                <li>
                  <div class="text_area">
                    <div class="m2_title">택배사 전산연동</div>
                    <div class="m2_text">EFS ↔ 택배사 전산 연동을 통한 물품 이동경로 추적 및 상세현황 파악 가능
                    </div>
                  </div>
                  <img src="../../assets/img/bg_main0203.png"/></li>
                <li>
                  <div class="text_area">
                    <div class="m2_title">재고 관리</div>
                    <div class="m2_text">이동 동선 및 보관구역에 따른 보안 시스템 구축 및 시스템 연동을 통한 안전재고량 파악 가능
                    </div>
                  </div>
                  <img src="../../assets/img/bg_main0204.png"/></li>
                <li>
                  <div class="text_area">
                    <div class="m2_title">배송 회수 리퍼 관리</div>
                    <div class="m2_text">다양한 형태의 현장 프로세스 구축 및 생산성 높은 라인 구성
                    </div>
                  </div>
                  <img src="../../assets/img/bg_main0205.png"/></li>
                <!-- items mirrored twice, total of 12 -->
              </ul>
            </div>
            <div id="carousel" class="flexslider pc">
              <ul class="slides list_slide">
                <li> 실시간 모니터링</li>
                <li> 일별작업 물량관리</li>
                <li> 택배사 전산연동</li>
                <li> 재고 관리</li>
                <li> 배송 회수 리퍼 관리</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="section" id="section3">

        <div class="wrapper intro">
          <!--          <router-view v-slot="{ Component }">-->
          <!--            <transition name="slide">-->
          <!--              <component :is="Component" />-->
          <!--            </transition>-->
          <!--          </router-view>-->
          <!--          <transition name="slide" mode="out-in">-->
          <!--            <component :is="view" />-->
          <!--          </transition>-->

          <transition :name="'fade-'+lord" mode="">
            <div class="pppp" v-if="slidePage === 1" key="saved">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="intro txt03">
                    <h1>Structure & Flow</h1>
                    <h2>구매의뢰</h2>
                    <div class="txt_sub">이트너스는 7년 연속 삼성전자 4스타 B2B대리점으로 국내 최고 브랜드 제품을 특별한 혜택으로
                      제공합니다.
                    </div>
                    <div class="box_btn">
                      <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/>
                      </button>
                      <div class="page"><span class="">{{ slidePage }}</span> <span
                          class="total_color">/ 5</span></div>
                      <button @click="next"><img src="../../assets/img/img_arrow_right.png"/>
                      </button>
                    </div>
                  </div>
                  <div class="img se0301"></div>
                </div>
              </div>
            </div>
            <div class="pppp" v-if="slidePage === 2" key="edited">
              <div class="swiper-slide">
                <div class="intro txt03">
                  <h1>Structure & Flow</h1>
                  <h2>제조사 협의 </h2>
                  <div class="txt_sub">각 제품별 전문 담당PM 운영으로 상품 공급/운영에 대한 전반적인 업무지원이 가능합니다.</div>
                  <div class="box_btn">
                    <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/></button>
                    <div class="page"><span class="">{{ slidePage }}</span> <span
                        class="total_color">/ 5</span></div>
                    <button @click="next"><img src="../../assets/img/img_arrow_right.png"/></button>
                  </div>
                </div>
                <div class="img se0302"></div>
              </div>
            </div>
            <div class="pppp" v-if="slidePage === 3" key="editing">
              <div class="swiper-slide">
                <div class="intro txt03">
                  <h1>Structure & Flow</h1>
                  <h2>현장실사</h2>
                  <div class="txt_sub">시스템을 기반의 체계적인 재고관리로 고객사의 소중한 자산을 효율적으로 관리 가능 합니다.</div>
                  <div class="box_btn">
                    <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/></button>
                    <div class="page"><span class="">{{ slidePage }}</span> <span
                        class="total_color">/ 5</span></div>
                    <button @click="next"><img src="../../assets/img/img_arrow_right.png"/></button>
                  </div>
                </div>
                <div class="img se0303"></div>
              </div>
            </div>
            <!-- <div class="pppp" v-if="slidePage === 4" key="editing2">
              <div class="swiper-slide">
                <div class="intro txt03">
                  <h1>Structure & Flow</h1>
                  <h2>계약체결</h2>
                  <div class="txt_sub">문구없음</div>
                  <div class="box_btn">
                    <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/></button>
                    <div class="page"><span class="">{{ slidePage }}</span> <span
                        class="total_color">/ 6</span></div>
                    <button @click="next"><img src="../../assets/img/img_arrow_right.png"/></button>
                  </div>
                </div>
                <div class="img se0304"></div>
              </div>
            </div> -->
            <div class="pppp" v-if="slidePage === 4" key="editing5">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="intro txt03">
                    <h1>Structure & Flow</h1>
                    <h2>태블릿pc공급</h2>
                    <div class="txt_sub">이트너스는 EFS 물류 솔루션을 통해 고객 특성에 맞는 솔루션과 교육 서비스 이용자와 사업 운영에 맞는
                      적합한 상품을 소싱/제공 합니다.
                    </div>
                    <div class="box_btn">
                      <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/>
                      </button>
                      <div class="page"><span class="">{{ slidePage }}</span> <span
                          class="total_color">/ 5</span></div>
                      <button @click="next"><img src="../../assets/img/img_arrow_right.png"/>
                      </button>
                    </div>
                  </div>
                  <div class="img se0305"></div>
                </div>
              </div>
            </div>
            <div class="pppp" v-if="slidePage === 5" key="editing6">
              <div class="swiper-slide">
                <div class="intro txt03">
                  <h1>Structure & Flow</h1>
                  <h2>EFS서비스 운영</h2>
                  <div class="txt_sub">ETNERS FULFILLMENT SOLUTION은 양방향 물류에 특화된 시스템을 제공하여 컨설팅, 시스템
                    기획, 운영, 공급, 관리 등 물류에 필요한 모든 서비스를 지원합니다.
                  </div>
                  <div class="box_btn">
                    <button @click="prev"><img src="../../assets/img/img_arrow_left.png"/></button>
                    <div class="page"><span class="">{{ slidePage }}</span> <span
                        class="total_color">/ 5</span></div>
                    <button @click="next"><img src="../../assets/img/img_arrow_right.png"/></button>
                  </div>
                </div>
                <div class="img se0306"></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="section" id="section4">
        <div class="intro">
          <h1>PROCESS</h1>
          <div class="main">
            <input id="tab1" type="radio" name="tabs" v-model="selectedProcess" value="prcs_1">
            <label for="tab1" :class="'btn_01 ' + (selectedProcess === 'prcs_1' ? 'on' : '')">
              <div class="title">회수/검품</div>
              <div class="txt_cont">체험 종료 후 반납된 물품을 검수 하는 공정</div>
            </label>
            <input id="tab2" type="radio" name="tabs" v-model="selectedProcess" value="prcs_2">
            <label for="tab2"  :class="'btn_02 ' + (selectedProcess === 'prcs_2' ? 'on' : '')">
              <div class="title">리퍼</div>
              <div class="txt_cont">어카운트 체험 상품별
                기기 런처 설치, 클리닝,
                상품화 작업 공정
              </div>
            </label>
            <input id="tab3" type="radio" name="tabs" v-model="selectedProcess" value="prcs_3">
            <label for="tab3" :class="'btn_03 ' + (selectedProcess === 'prcs_3' ? 'on' : '')">
              <div class="title">발송</div>
              <div class="txt_cont">상품 코드별
                전산 발송 등록 작업 및
                상품박스 택배화 작업 공정
              </div>
            </label>
            <section id="content1" v-show="selectedProcess === 'prcs_1'">
              <ul class="process_list pc">
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div class="title">
                    <div>01</div>
                    <div>회수품 입고등록</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040101.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                  <div class="title">
                    <div>02</div>
                    <div>회수품 적재</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040102.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div class="title">
                    <div>03</div>
                    <div>회수품 박스 해제</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040103.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div class="title">
                    <div>04</div>
                    <div>구성품 분류</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040104.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div class="title">
                    <div>05</div>
                    <div>분실, 파손 기록</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040105.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                  <div class="title">
                    <div>06</div>
                    <div>시스템 검품등록</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040106.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                  <div class="title">
                    <div>07</div>
                    <div>리퍼 준비 완료</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040107.png"/></div>
                </li>
              </ul>
              <ul class="mobile">
                <li><img class="img_l4" src="../../assets/img/bg_002.png"/></li>
              </ul>
            </section>
            <section id="content2" v-show="selectedProcess === 'prcs_2'">
              <ul class="process_list pc">
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div class="title">
                    <div>01</div>
                    <div>디바이스 충전테스트</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040201.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                  <div class="title">
                    <div>02</div>
                    <div>디바이스 초기화</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040202.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div class="title">
                    <div>03</div>
                    <div>바코드 출력 및 부착</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040203.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div class="title">
                    <div>04</div>
                    <div>상품별 런처설치</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040204.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div class="title">
                    <div>05</div>
                    <div>제품 클리닝 진행</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040205.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                  <div class="title">
                    <div>06</div>
                    <div>상품화 진행</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040206.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                  <div class="title">
                    <div>07</div>
                    <div>시스템 리퍼 등록</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040207.png"/></div>
                </li>
              </ul>
              <ul class="mobile">
                <li><img class="img_l4" src="../../assets/img/bg_001.png"/></li>
              </ul>
            </section>
            <section id="content3" v-show="selectedProcess === 'prcs_3'">
              <ul class="process_list pc">
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div class="title">
                    <div>01</div>
                    <div>운송장 부착</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040301.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                  <div class="title">
                    <div>02</div>
                    <div>택배 박스 조립</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040302.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div class="title">
                    <div>03</div>
                    <div>상품 구성품 삽입</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040303.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div class="title">
                    <div>04</div>
                    <div>중량 선별기 무게증빙</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040304.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div class="title">
                    <div>05</div>
                    <div>시스템 발송 등록</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040304.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                  <div class="title">
                    <div>06</div>
                    <div>배송 상차</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040305.png"/></div>
                </li>
                <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                  <div class="title">
                    <div>07</div>
                    <div>제품 출고 완료</div>
                  </div>
                  <div class="circle"><img src="../../assets/img/bg_main040306.png"/></div>
                </li>
              </ul>
              <ul class="mobile">
                <li><img class="img_l4" src="../../assets/img/bg_003.png"/></li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div class="section" id="section5">
        <div class="intro">
          <h1>BENEFIT</h1>
          <div id="roll" class="pc2">
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
              <div class="text_box">
                  <h5>업무컨설팅</h5>
                  <div class="sub_txt">
                    <div class="before"></div>태블릿 교육 사업을 시작하려는데, 어디서 부터 시작해야 할 지 막막합니다.<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                        10개 이상의 교육기업을 통해 확보한 운영 ,기획 KNOW-HOW을 활용하여 최적의 솔루션을 제공합니다. 기업의 브랜드 이미지에 부합하는 최적의 주변기기 및 부자재, 소모품 제작품 공급가능합니다.
                  </div>
              </div>
                  <div class="inner wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                    <img src="../../assets/img/sc5_001.png"/>
                  </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='90'>
              <div class="text_box">
                  <h5>시스템 Customizing</h5>
                  <div class="sub_txt"><div class="before"></div>전체적인 공정 현황을 한 눈에 볼수 있나요? <br> 보유하고 있는 재고의 수량을 매일 확인할 수 있나요?   <div class="after"></div>
                  </div>
                  <div class="cont_txt">
                    클라이언트 Needs에 맞는 개발 지원이 가능한 자체 시스템 System 보유
                  </div>
                </div>
                  <div class="inner wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                  <img src="../../assets/img/sc5_002.png"/>
                </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='90'>
                <div class="text_box">
                  <h5>파트 별 작업 공간 분리 및 담당 PM배치</h5>
                  <div class="sub_txt"><div class="before"></div>발송 물량이 많은 편인데, 회수, 발송 문제없이 가능한가요? 구성품목이 다양한데, 누락 없이 발송이 가능한가요?<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                    배송, 회수, 생산, 재고, 운영 등 담당PM을 배치하여 업무 효율성을 극대화 각 공정별 작업공간을 분리 운영하여 리퍼, 배송, 회수의 파트 별 생산량 증대 및 전문적인 라인 구축
                  </div>
                </div>
                  <div class="inner wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                <img src="../../assets/img/sc5_003.png"/>
                </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='90'>
              <div class="text_box">
                  <h5>Black Consumer 대응 카메라운용</h5>
                  <div class="sub_txt"><div class="before"></div>미회수 품목에 대해 증빙을 해주실 수 있나요? 구성품을 못 받았다는 클레임 확인이 가능한가요?<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                    발송, 회수 진행 시 구성품 확인이 가능한 Camera 운영으로 Black consumer 대응 영상 제공. 발송시 각 상품별 중량선별기를 도입하여 구성품 누락 제로화 가능
                  </div>
              </div>
                    <div class="inner wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <img src="../../assets/img/sc5_004.png"/>
              </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='90'>
              <div class="text_box">
                <h5>화재 방지 시스템 화재 보험 가입</h5>
                <div class="sub_txt"><div class="before"></div>고가의 자산을 안전하게 보관해주실 수 있으신가요<div class="after"></div>
                </div>
                <div class="cont_txt">
                  고가의 클라이언트 자산을 지킬 수 있도록 삼성화재 최대 20억 화재보험 가입

                </div>
              </div>
                <div class="inner wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
              <img src="../../assets/img/sc5_005.png"/>
            </div>
            </div>
          </div>
          <div id="roll" class="mobile">
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
                  <h5>업무컨설팅</h5>
                  <div class="sub_txt">
                    <div class="before"></div>태블릿 교육 사업을 시작하려는데, 어디서 부터 시작해야 할 지 막막합니다.<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                        10개 이상의 교육기업을 통해 확보한 운영 ,기획 KNOW-HOW을 활용하여 최적의 솔루션을 제공합니다. 기업의 브랜드 이미지에 부합하는 최적의 주변기기 및 부자재, 소모품 제작품 공급가능합니다.
                  </div>
                  <div class="inner">
                    <img src="../../assets/img/sc5_001.png"/>
                  </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
                  <h5>시스템 Customizing</h5>
                  <div class="sub_txt"><div class="before"></div>전체적인 공정 현황을 한 눈에 볼수 있나요? 보유하고 있는 재고의 수량을 매일 확인할 수 있나요?   <div class="after"></div>

                  </div>
                  <div class="cont_txt">
                    클라이언트 Needs에 맞는 개발 지원이 가능한 자체 시스템 System 보유
                  </div>
                  <div class="inner" style="">
                  <img src="../../assets/img/sc5_002.png"/>
                </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
                  <h5>파트 별 작업 공간 분리 및 담당 PM배치</h5>
                  <div class="sub_txt"><div class="before"></div>발송 물량이 많은 편인데, 회수, 발송 문제없이 가능한가요? 구성품목이 다양한데, 누락 없이 발송이 가능한가요?<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                    배송, 회수, 생산, 재고, 운영 등 담당PM을 배치하여 업무 효율성을 극대화.  각 공정별 작업공간을 분리 운영하여 리퍼, 배송, 회수의 파트 별 생산량 증대 및 전문적인 라인 구축
                  </div>
                  <div class="inner" style="">
                <img src="../../assets/img/sc5_003.png"/>
                </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
                  <h5>Black Consumer 대응 카메라운용</h5>
                  <div class="sub_txt"><div class="before"></div>미회수 품목에 대해 증빙을 해주실 수 있나요? 구성품을 못 받았다는 클레임 확인이 가능한가요?<div class="after"></div>
                  </div>
                  <div class="cont_txt">
                    발송, 회수 진행 시 구성품 확인이 가능한 Camera 운영으로 Black consumer 대응 영상 제공. 발송시 각 상품별 중량선별기를 도입하여 구성품 누락 제로화 가능

                  </div>
                    <div class="inner" style="">
                <img src="../../assets/img/sc5_004.png"/>
              </div>
            </div>
            <div class="box_bn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
                <h5>화재 방지 시스템 화재 보험 가입</h5>
                <div class="sub_txt"><div class="before"></div>고가의 자산을 안전하게 보관해주실 수 있으신가요<div class="after"></div>
                </div>
                <div class="cont_txt">
                  고가의 클라이언트 자산을 지킬 수 있도록 삼성화재 최대 20억 화재보험 가입
                </div>
                <div class="inner" style="">
              <img src="../../assets/img/sc5_005.png"/>
            </div>
            </div>
          </div>
         </div>
      </div>
      <div class="section external" id="section6">
        <div class="intro">
          <h1>HISTORY</h1>
          <div class="history_min">
          <!-- <transition name="fade">
            <history-swiper v-show="fullPageIndex === 6"/>
          </transition> -->
          <history-swiper />
          </div>
        </div>
      </div>
      <div class="section" id="section7">
        <div class="intro">
          <h1>REFERENCE</h1>
          <div class="top_ref">
            <ul>
              <li>
                <div>연간 디바이스공급 규모</div>
                <div class="number"><span class="txt_s">약</span>{{ timeCount0 }}<span class="txt_s">억원</span></div>
              </li>
              <li>
                <div>연간 주변기기공급 규모</div>
                <div class="number"><span class="txt_s">약</span>{{ timeCount1 }}<span class="txt_s">억원</span></div>
              </li>
              <li>
                <div>연간 주변기기공급 규모</div>
                <div>{{ timeCount2 | comma }}대</div>
              </li>
              <li>
                <div>연간 리퍼</div>
                <div>{{ timeCount3 | comma }}건</div>
              </li>
              <li>
                <div>주변기기 공급 물량</div>
                <div>{{ timeCount4 | comma }}건</div>
              </li>
              <li>
                <div>연간회수</div>
                <div>{{ timeCount5 | comma }}건</div>
              </li>
            </ul>
          </div>
          <div class="bottom_ref">
            <ul>
              <li><img src="../../assets/img/bn0701.png"/></li>
              <li><img src="../../assets/img/bn0702.png"/></li>
              <li><img src="../../assets/img/bn0703.png"/></li>
              <li><img src="../../assets/img/bn0704.png"/></li>
              <li><img src="../../assets/img/bn0705.png"/></li>
              <li><img src="../../assets/img/bn0706.png"/></li>
              <li><img src="../../assets/img/bn0707.png"/></li>
              <li><img src="../../assets/img/bn0708.png"/></li>
              <li><img src="../../assets/img/bn0709.png"/></li>
              <li><img src="../../assets/img/bn0710.png"/></li>
              <li><img src="../../assets/img/bn0711.png"/></li>
              <li><img src="../../assets/img/bn0712.png"/></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section certification" id="section8">
        <div class="intro">
          <h1>CERTIFICATION </h1>
          <h2>EFS의 특장점으로 경쟁력있는 서비스를 제공합니다.</h2>
          <div class="ct_list">
            <li>
              <div class="ct_img"><img class="" src="../../assets/img/img_ct01.jpg" /></div>
              <div class="ct_txt_box">
                <div class="ct_title">
                  클라우드 비전인식 물류 자동화 서버
                </div>
                <div class="ct_s_title">
                  클라우드 기반 인공지능을 이용한 지능형 물류센터 상품 입출고 등록 자동화 방법 및 이를 실행하는 서버
                </div>
                <dl class="box_ct_info">
                  <dt>출원일자</dt>
                  <dd>2022년 07월 05일</dd>
                  <dt>출원번호</dt>
                  <dd>제 10-2022-0082196호</dd>
                  <dt>특허권자</dt>
                  <dd>주식회사 이트너스</dd>
                  <dt>발명의 내용</dt>
                  <dd>클라우드 기반 인공지능을 이용한 지능형 물류센터 상품 입출고 등록 자동화 방법 및 이를 실행하는 서버</dd>
                  <dt>특허번호</dt>
                  <dd>제 10- 2493942</dd>
                </dl>
              </div>
            </li>
            <!-- <li>
              <div class="ct_img"><img class="" src="images/img_ct02.png" /></div>
              <div class="ct_txt_box hvr-sweep-to-right">
                <div class="ct_title">
                  더 가깝더 신선하게
                </div>
                <div class="ct_s_title">
                  물품 구입에서 포장 배송까지
                  원스탑 서비스가 가능한 복지 솔루션
                </div>
                <dl class="box_ct_info">
                  <dt>출원일자</dt>
                  <dd>2018년 12월 05일</dd>
                  <dt>출원번호</dt>
                  <dd>제 10-2018-0155288호</dd>
                  <dt>특허권자</dt>
                  <dd>주식회사 이트너스</dd>
                  <dt>발명의 내용</dt>
                  <dd>국가간 구매 및 배송에 특화된 온라인 쇼핑몰 제공 방법 및 장치</dd>
                  <dt>특허번호</dt>
                  <dd>제 10-2013269호</dd>
                </dl>
              </div>
            </li> -->
          </div>
        </div>
      </div>
      <div class="section" id="section9" style="height:90vh !important">
        <div class="intro box_flex">
          <div class="bg_left"><img src="../../assets/img/bg_sec8_txt.png"/></div>
          <div class="box_contact">
            <h1>CONTACT US</h1>
            <div class="box_flex_row">
              <div>회사명
                <input ref="cmpyNmRef" maxlength="20" type="text" v-model.trim="contactInfo.cmpyNm"></div>
              <div class="box_flex">
                <div>담당자명
                  <input ref="contacterNmRef" maxlength="10" type="text" v-model.trim="contactInfo.contacterNm">
                </div>
                <div class="mgl10">휴대전화번호
                  <input ref="contacterTelNoRef"
                         type="text"
                         v-model.trim="contactInfo.contacterTelNo"
                         maxlength="11"
                         @input="() => {contactInfo.contacterTelNo = contactInfo.contacterTelNo.replace(/[^0-9]/g, '')}">
                </div>
              </div>
              <div>문의유형
                <select ref="contactTypeRef"
                        v-model="contactInfo.contactType">
                  <option v-for="item in contactTypeList" :value="item.cdId"> {{ item.cdNm }}</option>
                </select>
              </div>
              <div>상담내용
                <textarea ref="contentsTextRef"
                          type="text"
                          maxlength="1000"
                          v-model.trim="contactInfo.contentsText"></textarea>
              </div>
              <div class="txt_l checkbox ">
                <label>
                  <input v-model="contactInfo.isCheck" type="checkbox"><span>[필수]개인정보 취급방침 동의<a @click="popup('privacy')">[전문보기]</a></span>
                </label><br>
                <label>
                  <input v-model="contactInfo.isMarketCheck" type="checkbox"><span>[선택]마케팅 활용 동의<a @click="popup('marketing')">[전문보기]</a></span>
                </label>
              </div>
              <div @click="onClickSaveContact" class="button_enter">문의하기</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <popup-for-landing
        ref="popupRef">
    </popup-for-landing>
  </div>
</template>


<script>
import $ from 'jquery'
import '/src/common/landing/jquery.flexslider'
import '/src/common/landing/jquery.fullPage'
import HistorySwiper from '../components/HistorySwiper'
import {WOW} from 'wowjs';
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import PopupForLanding from '@/landing/components/PopupForLanding'
import EtnersCommonUtil from "@ebmp-fe-common-module/module/src/common/etners.common.util"

export default {
  name: 'Landing',
  computed: {},
  components: {
    HistorySwiper,
    PopupForLanding,
  },
  watch : {
    fullPageIndex :function(newValue, oloValue) {
      const vm = this
      if(newValue === 7){
        vm.timeCounter()
      }else if(newValue === 9){
        vm.popCss = "display:block;"

      }
    }
  },
  created () {
    const vm = this
    vm.getContactTypeList()
  },
  mounted () {
    new WOW().init();
    const vm = this
    /*-풀페이지 스크롤 -*/
    $('#fullpage').fullpage({
      //이동
      menu: '#menu',
      // lockAnchors: false,
      navigation: true,
      navigationPosition: 'right',
      // navigationTooltips: ['firstSlide', 'secondSlide'],
      showActiveTooltip: false,
      // slidesNavigation: true,
      slidesNavPosition: 'bottom',
      responsiveWidth: 4000,
      //스크롤
      css3: true,
      afterLoad: function(anchorLink, index){
        vm.fullPageIndex = index
      },
      afterSlideLoad: function( anchorLink, index, slideAnchor, slideIndex){
        vm.aboutSlidePage = slideIndex
      }
    })
    vm.aboutMoveInterval = setInterval(function() {
      fullpage_api.moveSlideRight()
    }, 7000);

    $('#carousel').flexslider({
      animation: 'slide',
      controlNav: true,
      animationLoop: true,
      slideshow: true,
      itemWidth: 20,
      itemmaxWidth: 200,
      itemMargin: 5,
      asNavFor: '#slider'
    })
    $('#slider').flexslider({
      animation: 'slide',
      controlNav: false,
      animationLoop: true,
      slideshow: true,
      slideshowSpeed: 5000,
      animationSpeed: 600,
      smoothHeight:false,
      // keyboard: ture,
      pauseOnHover:false,
      innerHeight: '100%',
      sync: '#carousel',
      start: function (slider) {
        // $('body').removeClass('loading')
      }
    })

    // pricing, 서비스신청 등 렌딩페이지의 헤더내의 다른 라우터에서 오는 경우
    vm.goSection(this.$route.params.num)
    if(!!this.$route.query.num){
      vm.goSection(this.$route.query.num)
    }

    this.setVisitHis();

    window.addEventListener("beforeunload", this.unLoadEvent)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent)
  },
  beforeDestroy () {
    const vm = this
    fullpage_api.destroy('all')
    clearInterval(vm.aboutMoveInterval)
  },
  methods: {
    unLoadEvent() {
      if (!navigator.sendBeacon) return
      // navigator.sendBeacon(`${ApiConfig.homeDomain}/v1/eth/summary/receiveBeacon.json` + "?data=" + this.getCookie("visitId"));
      navigator.sendBeacon(`${ApiConfig.homeDomain}/v1/eth/summary/receiveBeacon.json` + "?data=" + sessionStorage.getItem("visitId") ?? "");

      // this.delCookie('visitId')
      sessionStorage.removeItem('visitId')
    },


    // setCookie(key, value, expiredays) {
    //   let todayDate = new Date();
    //   todayDate.setDate(todayDate.getDate() + expiredays)
    //   document.cookie = key + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    // },
    // getCookie(key){
    //   key = new RegExp(key + '=([^;]*)')
    //   return key.test(document.cookie) ? unescape(RegExp.$1) : ''
    // },
    // delCookie(key){
    //   let todayDate = new Date()
    //   document.cookie = key + "=; path=/; expires=" + todayDate.toGMTString() + ";"
    // },
    setVisitHis : function(){
      const vm = this
      // let visitId = this.getCookie("visitId")??""
      let visitId = sessionStorage.getItem("visitId")??""
      let param = { visitId, solCd : "10"}
      let url = `${ApiConfig.homeDomain}/v1/eth/summary/setVisitHis.json`

      $.ajax({
        url : url,
        method: "POST",
        contentType:'application/json',
        data:JSON.stringify(param),
        success : function(response){
          const visitId = sessionStorage.getItem("visitId")
          // if (vm.getCookie("visitId") == "") {
          if (!visitId || visitId == "" ) {
            // vm.setCookie("visitId", response.resultData.visitId, 1)
            sessionStorage.setItem("visitId", response.resultData.visitId)
          }
        }
      });
    },
    fn_setTabIndexMove : function(name){

      document.getElementById(name).focus()
      //this.$ref.name.focus

    },
    fn_setPopupCss : function(type){
      if(type==="info"){
        $('#proposal_info').attr('style','display: none;')
      }else{
        $('#proposal_download').attr('style','display: none;')
      }

    },
    popup: function (fileNm) {
      if (fileNm === 'privacy') {
        window.open('//etners.com/terms/management/detail/10/02')
      }else{
        window.open('//etners.com/'+fileNm+'.html?solCd=10')
      }
    },
    onClickSaveContact : function (){
      const vm = this

      if(contactValidator()){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/contact`, vm.contactInfo).then((response) => {
          if (
              response.data === undefined ||
              response.data.resultStatus === undefined
          ) {
            vm.$refs.popupRef.alert("데이터가 정상적으로 조회되지 않았습니다.")
            return false;
          }
          if (response.data.resultStatus.messageCode !== "2000") {
            vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
            return false;
          }

          //vm.$refs.popupRef.alert("상담내역이 접수되었습니다. <br>빠른시일내에 담당자를 통해서 연락드리겠습니다.")

          let params = {
            solCd                   : '10', // EFS 사업소개서 다운로드
            faqCategoryCd           : '01', //서비스문의
            companyNmTx             : vm.contactInfo.cmpyNm,
            managerNmTx             : vm.contactInfo.contacterNm,
            emailTx                 : vm.contactInfo.contacterNm,
            qnaContentsTx           : vm.contactInfo.contentsText,
            phoneNoTx               : vm.contactInfo.contacterTelNo,
            personalInfoAgreeYn     : vm.contactInfo.isCheck ? 'Y':'N',
            marketingAgreeYn        : vm.contactInfo.isMarketCheck ? 'Y' : 'N'
          }

          this.fnQnaDownloadHis(params)


          vm.contactInfo = {
            contactType : '01'
          }
        })
      }

      function contactValidator(){
        if(!vm.contactInfo.cmpyNm){
          vm.$refs.popupRef.alert("회사명을 입력해주세요.", function (){
            vm.$refs.cmpyNmRef.focus()
          })
          return false
        }
        if(!vm.contactInfo.contacterNm){
          vm.$refs.popupRef.alert("담당자명을 입력해주세요.", function (){
            vm.$refs.contacterNmRef.focus()
          })
          return false
        }
        if(!vm.contactInfo.contacterTelNo){
          vm.$refs.popupRef.alert("휴대전화번호를 입력해주세요.", function (){
            vm.$refs.contacterTelNoRef.focus()
          })
          return false
        }
        if(!vm.contactInfo.contactType){
          vm.$refs.popupRef.alert("문의유형을 선택해주세요.", function (){
            vm.$refs.contactTypeRef.focus()
          })
          return false
        }
        if(!vm.contactInfo.contentsText){
          vm.$refs.popupRef.alert("상담내용을 입력해주세요.", function (){
            vm.$refs.contentsTextRef.focus()
          })
          return false
        }
        if(!vm.contactInfo.isCheck){
          vm.$refs.popupRef.alert("개인정보 취급방침에 동의해 주세요")
          return false
        }

        return true
      }
    },
    getContactTypeList : function (){
      const vm = this
      if(!vm.$store.state.codeRequestStore.search.combobox.contactType?.data?.length > 0){
        ApiUtil.query(`${ApiConfig.efsDomain}/efs/code/038`).then((response) => {
          if (response.data === undefined) {
            vm.$refs.popupRef.alert("데이터가 정상적으로 조회되지 않았습니다.")
            return false;
          }
          vm.contactTypeList = response.data
        })
      }else {
        vm.contactTypeList = vm.$store.state.codeRequestStore.search.combobox.contactType?.data
      }
    },
    timeCounter : function (){
      const vm = this
      let interval0 = setInterval(count0Fn, 7);

      let interval2, interval3, interval4, interval5
      function count0Fn() {
        if (vm.timeCount0 >= 350) {
          clearInterval(interval0);
          interval2 = setInterval(count2Fn, 15);
          interval3 = setInterval(count3Fn, 15);
          interval4 = setInterval(count4Fn, 15);
          interval5 = setInterval(count5Fn, 15);
        } else {
          vm.timeCount0++
        }
      }
      let interval1 = setInterval(count1Fn, 50);
      function count1Fn() {
        if (vm.timeCount1 >= 50) {
          clearInterval(interval1);
        } else {
          vm.timeCount1++
        }
      }

      function count2Fn() {
        if (vm.timeCount2 >= 140000) {
          vm.timeCount2 = 140000
          clearInterval(interval2);
        } else {
          vm.timeCount2+= 789
        }
      }

      function count3Fn() {
        if (vm.timeCount3 >= 220000) {
          vm.timeCount3 = 220000
          clearInterval(interval3);
        } else {
          vm.timeCount3+= 1234
        }
      }

      function count4Fn() {
        if (vm.timeCount4 >= 105000) {
          vm.timeCount4 = 105000
          clearInterval(interval4);
        } else {
          vm.timeCount4+= 567
        }
      }

      function count5Fn() {
        if (vm.timeCount5 >= 433000) {
          vm.timeCount5 = 433000
          clearInterval(interval5);
        } else {
          vm.timeCount5 += 2345
        }
      }


    },
    goSection: function (section) {
      fullpage_api.moveTo(section)
    },
    goSlide: function (section, slide = null) {
      const vm = this
      vm.aboutSlidePage = slide
      if(vm.aboutSlidePage > 2){
        vm.aboutSlidePage = 0
      }
      fullpage_api.moveTo(section, vm.aboutSlidePage)
    },
    next: function () {
      const vm = this
      vm.lord = 'right'
      vm.slidePage++
      if (vm.slidePage === 6) {
        vm.slidePage = 1
      }
    },
    prev: function () {
      const vm = this
      vm.lord = 'left'
      vm.slidePage--
      if (vm.slidePage === 0) {
        vm.slidePage = 5
      }
    },
    setMatchedPhone: function(value){
      const matchedPhone = /[0-9]$/.test(value)

      if(!matchedPhone){
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      }else{
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

    },
    getDefaultRules: function(){
      const vm = this

      const resultMsg = { key: true, msg: '' }


      if (!vm.vModel.companyNmTx) {
        resultMsg.key = false
        resultMsg.msg = '회사명을 입력해주세요.'
      }else if (!vm.vModel.phoneNoTx) {
        resultMsg.key = false
        resultMsg.msg = '연락처를 입력해주세요.'
      }else if (!vm.vModel.emailTx) {
        resultMsg.key = false
        resultMsg.msg = '이메일을 입력해주세요.'
      }else if (!vm.vModel.managerNmTx) {
        resultMsg.key = false
        resultMsg.msg = '성명을 입력해주세요.'
      }

      return resultMsg

    },
    getEmailRules: function () {
      const resultMsg = { key: true, msg: '' }
      const emailId = this.vModel.emailTx
      const matched = /^([\w-\.]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(emailId)

      if ( EtnersCommonUtil.isEmpty(emailId)) {
        resultMsg.key = false
        resultMsg.msg = '이메일을 입력해주세요.'
      } else if (!matched) {
        resultMsg.key = false
        resultMsg.msg = '이메일 형식을 맞춰주세요.'
      }
      return resultMsg
    },
    fnSubmit: function(){
      const vm = this

      const vModel = vm.vModel
      const defaultResultMsg = this.getDefaultRules()
      const emailRules = this.getEmailRules()

      if(defaultResultMsg.key){
        if(emailRules.key){
          if (!vModel.agree_pledge) {
            vm.$refs.popupRef.alert('개인정보 활용 내용에 동의해주세요. ')
            return false
          }


          let params = {
            solCd                   : '10', // EFS 사업소개서 다운로드
            companyNmTx             : vModel.companyNmTx,
            managerNmTx             : vModel.managerNmTx,
            emailTx                 : vModel.emailTx,
            phoneNoTx               : vModel.phoneNoTx,
            personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
            marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
          }

          this.fnProposalDownload(params)


        }else{
          vm.$refs.popupRef.alert(emailRules.msg)
          return false
        }
      }else{
        vm.$refs.popupRef.alert(defaultResultMsg.msg)
        return false
      }

    },
    fnQnaDownloadHis : function(params){
      let vm = this

      ApiUtil.post(`${ApiConfig.homeDomain}/v1/eth/qna/question/questionInsert.json`,params).then(response => {

        //let resultData = response.data.resultData

        if (response.data === undefined || response.data.resultStatus === undefined) {
          vm.$refs.popupRef.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }

        if (response.data.resultStatus.messageCode !== '2000') {
          vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
          return false
        }

        this.fnProposalDownload(params)



        })

    },
    fnProposalDownload : function(params){
      let vm = this

      ApiUtil.post(`${ApiConfig.homeDomain}/v1/eth/qna/proposal/downloadInsert.json`,params).then(response => {

        let resultData = response.data.resultData

        if (response.data === undefined || response.data.resultStatus === undefined) {
          vm.$refs.popupRef.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }

        if (response.data.resultStatus.messageCode !== '2000') {
          vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
          return false
        }

        $("#proposal_info").attr('style','display:none')
        $("#proposal_download").attr('style','display:block')

        this.downloadUrl = `${ApiConfig.homeDomain}/download/`+resultData.fileId +`/0`

      })

    },



  },
  data () {
    const vm = this
    return {
      contactTypeList : [],
      contactInfo : {
        contactType : '01'
      },
      selectedProcess : 'prcs_1',
      timeCount0 : 0,
      timeCount1 : 0,
      timeCount2 : 0,
      timeCount3 : 0,
      timeCount4 : 0,
      timeCount5 : 0,
      fullPageIndex : 1,
      aboutSlidePage: 0,
      benefitPage: 1,
      slidePage: 1,
      lord: 'd',
      isShow: false,
      show: true,
      aboutMoveInterval : null,
      vModel: {
        agree_pledge: null,
        agree_marketing: null
      },
      downloadUrl : ''
    }
  }

}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s
}
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
{
  opacity: 0
}
</style>
